html,
body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


div#__next {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* html,
body,
body > div:first-child,
*/
html,
body,
body>div:first-child,
div#__next {
  height: 100%;
}

body {
  --header-design: #CEFEFF;
  --header-business_innovation: #F2D2FC;
  --header-human_resource: #FFC6D5;
  --header-marketing: #F5FED1;
  --header-data_and_hard_skills: #D0B7FC;
  --header-product_management: #E6C4F8;
  --header-soft_skills: #F1B1BC;


  &[data-theme="hi"] {
    --primary-100: #FBEBDB;
    --primary-200: #f9e1c9;
    --primary-300: #f5cea6;
    --primary-400: #eb9d4e;
    --primary-500: #FF7300;

    /* Path headers by topic */
    --header-design: #E3DFD3;
    --header-business_innovation: #E3DFD3;
    --header-human_resource: #E3DFD3;
    --header-marketing: #E3DFD3;
    --header-data_and_hard_skills: #E3DFD3;
    --header-product_management: #E3DFD3;
    --header-soft_skills: #E3DFD3;
  }

  &[data-theme="tsh"],
  &[data-theme="hypertsh"] {
    --primary-100: #ffffff;
    --primary-200: #dcdcdc;
    --primary-300: #b3b3b3;
    --primary-400: #000000;
    --primary-500: #000000;
    --primary-700: #323232;

    /* Path headers by topic */
    --header-design: #e1e1e1;
    --header-business_innovation: #e1e1e1;
    --header-human_resource: #e1e1e1;
    --header-marketing: #e1e1e1;
    --header-data_and_hard_skills: #e1e1e1;
    --header-product_management: #e1e1e1;
    --header-soft_skills: #e1e1e1;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

:root {
  --zIndexLast: 0;
  --zIndexLow: 100;
  --zIndexMid: 200;
  --zIndexMidHigh: 250;
  --zIndexHigh: 300;
  --header-height: 4.4rem;
  --default-shadow: 0px 4px 20px rgba(15, 15, 15, 0.12);
  --support-burgundy-dark: #452935;
  --secondary-yellow-opacity30: #FFD670;
}

/* fix dropdown react components */
[data-radix-popper-content-wrapper] {
  z-index: 100 !important;

  &>div {
    margin: env(--space-4) env(--space-12);
  }
}


@keyframes slideDown {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}
